@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
@import url('https://fonts.googleapis.com/css?family=Roboto');

/* This is a ponyfill that provides client-side support for CSS custom properties (aka "CSS variables") in legacy and modern browsers
   More info here: https://github.com/jhildenbiddle/css-vars-ponyfill */

:root {
    /* Step 1: Brand Colors */
    /* --brand-default: 	                    #0e638d;
    --brand-primary: 	                    #8a002e;
    --brand-inverse: 	                    #2c3e50;
    --brand-info: 	                      #3943b7;
    --brand-success: 	                    #1fa51a;
    --brand-warning: 	                    #e08e45;
    --brand-danger: 	                    #8a002e;
    --brand-border:                       #c8c8c8; */

    /* == Typography */
    /* ## Change your font family, weight, line-height, headings and more (used in components/typography) */

    /* Font Family / False = fallback from Bootstrap (Helvetica Neue) */
    --font-primary: 	                    "Sul Sans";
    --font-second:                         "Sul Sans";

    /* Font Header Size */
    --font-size-h1: 	                     39px;
    --font-size-h2: 	                     30px;
    --font-size-h3: 	                     24px;
    --font-size-h4: 	                     19px;
    --font-size-h5:                        14px;
    --font-size-h6:                        11px;
    --font-average:                        14px;
    --font-size-16px:                      16px;

    /* Line Height */
    --line-height-base:                    1.428571429;

    /* == Gray darkens */
    /* ## Different gray darkens to be used for our variables and components 
    /* --gray-darker:                        #222;
    --gray-dark:                          #848484;
    --gray:                               #666;*/
    --gray-light:                         #999;
    /*--gray-primary:                       #ccc;
    --gray-lighter:                       #eee; */

    /* == Global Border Color */
    /* The default border color used by Datagrid, Listview, Tables, Dataview, Modals and more */
    /* --brand-default:                  --gray-lighter; */

    /* == Color variations */
    /* ## These variations are used to support several other variables and components */

    /* Basic Text colors */
    /* --text-dark:                             #000 ; */

    /* --text-light:                            #FFF; */

    /* Color variations */   
    /* --brand-default-dark:                    #0e428c;       
  
    --brand-primary-dark:                    #710025;    
  
    --brand-info-dark:                       #333ca4;      

    --brand-success-dark:                    #1b8f17;       
   
    --brand-warning-dark:                    #dc812f;      

    --brand-danger-dark:                     #710025;     */

    /* == Mobile */
    /* ##  */

    /* == Mobile Header */
    /* ## Specific mobile widgets and components (used in mobile/components/mx-header) */

    /* Height */
    --m-header-height:                       44px;

    /* Background Color */
    /* --m-header-bg:                           #FFF; */

    /* == Layout Spacing */
    /* ## Advanced layout options (used in base/mixins/default-spacing) */

    --default-spacing-top:                   35px;
    --default-spacing-right:                 40px;
    --default-spacing-bottom:                35px;
    --default-spacing-left:                  40px;

    --m-default-spacing-top:                 10px;
    --m-default-spacing-right:               15px;
    --m-default-spacing-bottom:              10px;
    --m-default-spacing-left:                15px;

    --t-default-spacing-top:                 20px;
    --t-default-spacing-right:               30px;
    --t-default-spacing-bottom:              20px;
    --t-default-spacing-left:                30px;

    /* == Layouts */
    /* ## */

    /* Scroll container / layout container default padding */
    --padding-layoutcontainer-base:          0px; 
    --m-padding-layoutcontainer-base:        0px;
    --t-padding-layoutcontainer-base:        0px; 

    /* Combined spacing  */
    --default-spacing:                       --default-spacing-top --default-spacing-right --default-spacing-bottom --default-spacing-left;
    --m-default-spacing:                     --m-default-spacing-top --m-default-spacing-right --m-default-spacing-bottom --m-default-spacing-left;
    --t-default-spacing:                     --t-default-spacing-top --t-default-spacing-right --t-default-spacing-bottom --t-default-spacing-left;

    /* == Media queries breakpoints */
    /* ## Define the breakpoints at which your layout will change, adapting to different screen sizes. */

    /* Extra small screen / phone Deprecated `--screen-xs` as of v3.0.1 */
    --screen-xs:                             480px;
    /* Deprecated `--screen-xs-min` as of v3.2.0 */
    --screen-xs-min:                         --screen-xs;
    /* Deprecated `--screen-phone` as of v3.0.1 */
    --screen-phone:                          --screen-xs-min;

    /* Small screen / tablet Deprecated `--screen-sm` as of v3.0.1 */
    --screen-sm:                             768px;
    --screen-sm-min:                         --screen-sm;
    /* Deprecated `--screen-tablet` as of v3.0.1 */
    --screen-tablet:                         --screen-sm-min;

    /* Medium screen / desktop Deprecated `--screen-md` as of v3.0.1 */
    --screen-md:                             992px;
    --screen-md-min:                         --screen-md;
    /* Deprecated `--screen-desktop` as of v3.0.1 */
    --screen-desktop:                        --screen-md-min;

    /* Large screen / wide desktop Deprecated `--screen-lg` as of v3.0.1 */
    --screen-lg:                             1200px;
    --screen-lg-min:                         --screen-lg;
    /* Deprecated `--screen-lg-desktop` as of v3.0.1 */
    --screen-lg-desktop:                     --screen-lg-min;

    /* So media queries don't overlap when required, provide a maximum */
    --screen-xs-max:                         (--screen-sm-min - 1);
    --screen-sm-max:                         (--screen-md-min - 1);
    --screen-md-max:                         (--screen-lg-min - 1);
  }