/* You can add global styles to this file, and also import other style files */
 /* @import 'bootstrap/dist/css/bootstrap.min.css';  */
@import './_custom-variables.css';

/* ==========================================================================
  Base

  Default settings
========================================================================== */
html {
  height: 100%;
}

body {
  font-family: var(--font-primary);
  color: #000;
  font-size: var(--font-size-16px);
  line-height: var(--line-height-base);
  /* background-color: white; */
  min-height: 100%;
}

/* a {
   color: var(--brand-info); 
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  -webkit-backface-visibility: hidden;
} */

a{
  font-family: var(--font-primary);
}

input, select, label {
  font-size: var(--font-size-16px) !important;
}

a:hover {
  text-decoration: underline;
  /* color: var(--brand-info); */
}

a:focus {
  outline: thin dotted;
}

a:active,
a:hover {
  outline: 0;
}

input:focus,
button:focus,
.mx-link:focus {
  outline: 0;
}

div[tabindex] {
  outline: 0;
}

legend {
  display: block;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

fieldset {
  width:100%;
}

/* ==========================================================================
    Typography
========================================================================== */
p {
  line-height: var(--line-height-base);
  font-size: 12px;
}

label {
  padding-top: 0;
  font-family: var(--font-primary);
}

.mx-title {
  /* color: var(--brand-danger); */
  font-size: 39px;
  font-family: var(--font-second);
  font-weight: 600;
}

h1, .h1, .h1 > * {
  font-size: var(--font-size-h1) !important;
  font-family: var(--font-second);
  font-weight: 600;
  /* color: #205B74; */
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--gray-light);
  /* text-transform: uppercase; */
} 

h2, .h2, .h2 > * {
  font-size: var(--font-size-h2) !important;
  font-family: var(--font-second);
  font-weight: 400;
  /* text-transform: uppercase; */
}

h3, .h3, .h3 > * {
  font-size: var(--font-size-h3) !important;
  font-family: var(--font-second);
  font-weight: 600;
}

h4, .h4, .h4 > * {
  font-size: var(--font-size-h4) !important;
  font-family: var(--font-primary);
  font-weight: 400;
}

h5, .h5, .h5 > * {
  font-size: var(--font-size-h5) !important;
  font-family: var(--font-second);
  font-weight: 600;
}

h6, .h6, .h6 > * {
  font-size: var(--font-size-h6) !important;
  font-family: var(--font-primary);
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: var(--line-height-base);
}

/* ==========================================================================
  Custom
========================================================================== */


.top-nav{
  min-height: 60px;
  border-bottom: 5px solid #A75168;
  /* background-color: var(--brand-primary);  */
  margin-top: 0px;
  margin-bottom: 0px;
}

.bottom-nav{
  /* background-color: var(--brand-primary);  */
  margin-bottom: 30px;
}

.footer-wrapper{ 
  padding-left: 20px;
  padding-right: 20px;
}

.footer-text{
  /* color: #999; */
  cursor: pointer;
}

.sidebar-title {
  font-family: var(--font-second);
  font-weight: 400;
  border-radius: 0;
  margin: 0;
  /* background: #FFF; */
  /* color: #999; */
  font-size: 18px;
  border: none;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #999;
}

.in-sidebar{
  padding: 10px 10px 15px 15px;
}

.modal-body{
  height: 400px;
  overflow-y: auto;
}

.benefits-border{
  border: 1px solid var(--brand-border);
}


.ui-steps .ui-steps-item {
  width: 25%;
}

.ui-steps.steps-custom {
  margin-bottom: 30px;
}

.ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
  height: 10px;
  padding: 0 1em;
  overflow: visible;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-number {
  /* background-color: #0081c2; */
  color: #FFFFFF;
  display: inline-block;
  width: 36px;
  border-radius: 50%;
  margin-top: -14px;
  margin-bottom: 10px;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-title {
  color: #555555;
}

.ui-dropdown{
  width:100% !important;
  font-family: var(--font-primary);
}

.ui-inputtext{
  width:100%;
  margin-bottom: 15px !important;
  font-family: var(--font-primary);
}


div .select-container{
  padding-bottom: 15px;
}

div .ui-accordion-content{
  font-family: var(--font-primary);
}

input, body .ui-widget, .form-control,div .AdditionalText,
div .brand-site-footer__copyright
{
  font-family: var(--font-primary) !important;
}
.input-styles{
  width: 100%;
  margin-bottom: 15px !important;
}

ng-select[name="lossType"] > ng-dropdown-panel > .ng-dropdown-panel-items {
  max-height: max-content !important;
}